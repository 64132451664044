/* Normalize styles for print */

/* Make body scrollable and spread out content in it */
body {
  -webkit-print-color-adjust: exact;
}
@media print {
  body {
    position: unset;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    overflow: unset;
  }

  body .page-break {
    page-break-after: always;
    page-break-before: always;
    display: block;
  }

  /* Main Container */
  body #main-container {
    position: unset;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
  }

  body #main-container .view-port-content-container > .page-viewer {
    top: 0;
  }

  /* Viewport styles */
  body #view-port-container .view-port-content-container .page-viewer {
    position: unset;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    overflow-y: unset;
    background: #fff;
  }

  /* Stacking plan */
  body .stacking-plan-view-container .page-content.stacking-plan-page-content {
    position: unset;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    overflow-y: unset;
  }

  body .stacking-plan-view-container .layout-container {
    display: flex;
    flex-direction: column;
    overflow-y: unset;
  }

  body .stacking-plan-view-container .layout-container .print-cover-page {
  }

  body .stacking-plan-view-container .floor.row {
    page-break-inside: avoid;
  }

  body .stacking-plan-view-container .layout-container {
    margin: 0;
  }
}
