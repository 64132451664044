@font-face {
  font-family: roboto-thin;
  src: url(./assets/fonts/Roboto/Roboto-Thin.ttf);
}

@font-face {
  font-family: roboto-light;
  src: url(./assets/fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: roboto-medium;
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: roboto-regular;
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: roboto-bold;
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: fira-sans-condensed-light;
  src: url(./assets/fonts/Fira_Sans_Condensed/FiraSansCondensed-Light.ttf);
}

@font-face {
  font-family: fira-sans-condensed-regular;
  src: url(./assets/fonts/Fira_Sans_Condensed/FiraSansCondensed-Regular.ttf);
}

@font-face {
  font-family: fira-sans-condensed-medium;
  src: url(./assets/fonts/Fira_Sans_Condensed/FiraSansCondensed-Medium.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: roboto-light, sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  color: #323c47;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

#root{
  height: 100%;
}

input, textarea{
  font-family: roboto-light;
  font-size: 14px;
  color: #989898;
}

label{
  font-family: roboto-medium;
  margin: 0;
}

.strong{
  font-family: roboto-regular;
}
